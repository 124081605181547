<template>
  <div>
    <!-- title -->
    <div style="height: 60px;background-color: var(--maxWhite)">
      <template v-if="!$common.isEmpty(currentChatFriendId)">
        <span style="line-height: 60px;margin-left: 20px;font-size: 18px">
          {{friends[currentChatFriendId].remark}}
        </span>
      </template>
      <template v-else-if="!$common.isEmpty(currentChatGroupId)">
        <span style="line-height: 60px;margin-left: 20px;font-size: 18px">
          {{groups[currentChatGroupId].groupName}}
        </span>
        <span style="line-height: 60px;margin-left: 20px;font-size: 12px;color: var(--greyFont)">
          当前在线人数：4
        </span>
      </template>
    </div>

    <!-- 聊天记录 -->
    <div class="msg-container">
      <template v-if="!$common.isEmpty(currentChatFriendId)">
        <div v-for="(item, index) in imMessages[currentChatFriendId]"
             :class="['msg-one', item.fromId === $store.state.currentUser.id ? 'message-right' : 'message-left']"
             :key="index">
          <!-- 头像 -->
          <div>
            <n-avatar object-fit="cover"
                      :size="40"
                      style="cursor: pointer"
                      @click="openFriendCircle(item.fromId, item.avatar)"
                      :src="item.avatar"/>
          </div>

          <!-- 文本消息 -->
          <div class="message" v-html="item.content"></div>

          <!-- 时间 -->
          <div class="msg-date" v-if="!$common.isEmpty(item.createTime)">
            {{$common.getDateDiff(item.createTime)}}
          </div>
        </div>
      </template>

      <template v-else-if="!$common.isEmpty(currentChatGroupId)">
        <div v-for="(item, index) in groupMessages[currentChatGroupId]"
             :class="['msg-one', item.fromId === $store.state.currentUser.id ? 'message-right' : 'message-left']"
             :key="index">
          <!-- 头像 -->
          <div>
            <n-avatar object-fit="cover"
                      :size="40"
                      style="cursor: pointer"
                      @click="openFriendCircle(item.fromId, item.avatar, item.username)"
                      :src="item.avatar"/>
          </div>

          <!-- 文本消息 -->
          <div class="message" v-html="item.content"
               :style="{'background': (item.fromId !== $store.state.currentUser.id ? $constant.tree_hole_color[item.fromId % $constant.tree_hole_color.length] : '')}">
          </div>

          <div class="msg-user">{{item.username}}</div>

          <!-- 时间 -->
          <div class="msg-date" style="margin-left: 5px;margin-right: 5px"
               v-if="!$common.isEmpty(item.createTime)">
            {{$common.getDateDiff(item.createTime)}}
          </div>
        </div>
      </template>
    </div>

    <!-- 输入框 -->
    <div style="height: 180px">
      <!-- 功能栏 -->
      <div style="padding: 10px 15px;display: flex;height: 25px">
        <!-- 表情 -->
        <n-popover placement="top-start"
                   display-directive="show"
                   trigger="click">
          <template #trigger>
            <div class="myEmoji">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M523.9 511.98m-419.5 0a419.5 419.5 0 1 0 839 0 419.5 419.5 0 1 0-839 0Z"
                  fill="#FFD629"></path>
                <path
                  d="M885.2 298.58c-1.6-0.6 37.1 68.4 49.5 128.9 46.4 227-99.9 448.6-326.9 495.1-37.9 7.8-75.7 10.1-112.5 7.7-63.2-4.2-123.6-22.8-177.3-53 0 0 348.9-269.7 567.2-578.7z"
                  fill="#FF9A2C" opacity=".1"></path>
                <path
                  d="M922.4 383.38c73.9 216.8-52.8 456-264 525.8-36.7 12.1-108.5 28.3-184.9 19.4 0-0.5 166.62-15.7 313.54-190.74C942.88 552.16 920.2 376.98 922.4 383.38z"
                  fill="#FF9A2C" opacity=".2"></path>
                <path
                  d="M790 184.48c-40.9-34.6-93.6-59.3-155.8-77.3-95.5-27.6-199.4-17.7-300.6 31.5-139.3 67.7-240.1 245.9-227.6 400.2 4.2 52 10.1 101.8 30 145.5 0.5 1 1.6 1.5 2.7 1.2 21.1-6.8 218.8-73.6 375.8-190.3 213.3-158.5 292.3-296.6 275.5-310.8z"
                  fill="#FCE99A" opacity=".24"></path>
                <path
                  d="M188.008782 408.574552a136 234.3 54.429 1 0 381.157036-272.589938 136 234.3 54.429 1 0-381.157036 272.589938Z"
                  fill="#F9F2D7" opacity=".2"></path>
                <path
                  d="M616.9 326.38m-52.7 0a52.7 52.7 0 1 0 105.4 0 52.7 52.7 0 1 0-105.4 0Z"
                  fill="#211715"></path>
                <path
                  d="M381.7 438.38l-77.6 125.3-15.5-8c-16-8.2-22.3-27.9-14.1-43.9l26.2-47.3-45.6-19.6c-15.7-8.1-21.9-27.4-13.8-43.1l8.2-16 132.2 52.6z"
                  fill="#211715"></path>
                <path
                  d="M603 479.68c27.4 56.5 14.1 119.5-29.7 140.8s-101.6-7.2-129-63.7l30.6-8c37.4-9.7 72.1-26.6 102.9-50l25.2-19.1z"
                  fill="#F94616"></path>
                <path
                  d="M514.5 567.38l8.8 6c3.6 2.5 8.3 2.8 12.2 0.7l26.9-14c3.4-1.8 5.7-5.1 6.3-8.9l1.5-10.5c1.4-10-9.4-17.1-18-11.9-9.1 5.5-20.9 11.8-35.4 17.7-8.8 3.6-10.1 15.5-2.3 20.9z"
                  fill="#E53600" opacity=".68"></path>
                <path
                  d="M922.4 383.38c73.9 216.8-52.8 456-264 525.8-36.7 12.1-108.5 28.3-184.9 19.4 0-0.5 122.92-45.83 275.21-216.29C910.21 531.51 920.2 376.98 922.4 383.38z"
                  fill="#FF9A2C" opacity=".19"></path>
                <path
                  d="M922.4 383.38c73.9 216.8-52.8 456-264 525.8-36.7 12.1-108.5 28.3-184.9 19.4 0-0.5 89.92-84.69 242.21-255.15C877.21 492.65 920.2 376.98 922.4 383.38z"
                  fill="#FF9A2C" opacity=".15"></path>
              </svg>
            </div>
          </template>
          <!-- 表情 -->
          <emoji @addEmoji="addEmoji" :showEmoji="true"></emoji>
        </n-popover>

        <!-- 图片 -->
        <div class="myEmoji" @click="sendPicture()">
          <svg viewBox="0 0 1024 1024" width="25" height="25">
            <path
              d="M512.649 512.632m-447.382 0a447.382 447.382 0 1 0 894.764 0 447.382 447.382 0 1 0-894.764 0Z"
              fill="#E0995E"></path>
            <path d="M736.34 428.748V316.902H288.958v447.382H736.34z"
                  fill="#231F20"></path>
            <path
              d="M736.34 260.979H288.958c-30.886 0-55.923 25.037-55.923 55.923V764.284c0 30.885 25.037 55.923 55.923 55.923H736.34c30.886 0 55.923-25.038 55.923-55.923V316.902c0-30.886-25.037-55.923-55.923-55.923z"
              fill="#231F20"></path>
            <path
              d="M288.958 764.284c-15.421 0-27.961-12.547-27.961-27.961V288.941c0-15.414 12.54-27.961 27.961-27.961H736.34c15.421 0 27.961 12.547 27.961 27.961v447.382c0 15.414-12.54 27.961-27.961 27.961H288.958z"
              fill="#77B3D4"></path>
            <path
              d="M736.34 288.941v447.383H288.958V288.941H736.34m0-55.923H288.958c-30.886 0-55.923 25.037-55.923 55.923v447.383c0 30.885 25.037 55.923 55.923 55.923H736.34c30.886 0 55.923-25.038 55.923-55.923V288.941c0-30.886-25.037-55.923-55.923-55.923z"
              fill="#FFFFFF"></path>
            <path d="M288.958 680.4H736.34v55.923H288.958z" fill="#FFFFFF"></path>
            <path
              d="M260.851 680.4h487.868v-40.837s-23.845-41.624-45.947-67.706c-16.44-19.4-40.989-24.891-62.407-1.059l-38.481 36.957-83.869-106.652c-31.234-39.718-82.344-39.718-113.578 0L260.851 680.4z"
              fill="#4F5D73"></path>
            <path
              d="M624.495 400.786m-41.942 0a41.942 41.942 0 1 0 83.884 0 41.942 41.942 0 1 0-83.884 0Z"
              fill="#F5CF87"></path>
            <path
              d="M736.34 288.941v447.383H288.958V288.941H736.34m0-55.923H288.958c-30.886 0-55.923 25.037-55.923 55.923v447.383c0 30.885 25.037 55.923 55.923 55.923H736.34c30.886 0 55.923-25.038 55.923-55.923V288.941c0-30.886-25.037-55.923-55.923-55.923z"
              fill="#FFFFFF"></path>
          </svg>
        </div>

        <!-- 表情包 -->
        <n-popover v-model:show="showPopoverImage"
                   display-directive="show"
                   placement="top-start"
                   trigger="click">
          <template #trigger>
            <div class="myEmoji" @click="showPopoverImage = !showPopoverImage">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M829.64898 849.502041H194.35102c-43.885714 0-79.412245-35.526531-79.412244-79.412245V253.910204c0-43.885714 35.526531-79.412245 79.412244-79.412245h635.29796c43.885714 0 79.412245 35.526531 79.412244 79.412245v516.179592c0 43.885714-35.526531 79.412245-79.412244 79.412245z"
                  fill="#D2F4FF"></path>
                <path
                  d="M909.061224 656.195918l-39.706122-48.065306L626.416327 365.714286c-19.330612-19.330612-50.677551-19.330612-70.008164 0L419.526531 502.073469c-2.612245 2.612245-5.22449 3.134694-6.791837 3.134694-1.567347 0-4.702041-0.522449-6.791837-3.134694L368.326531 464.979592c-19.330612-19.330612-50.677551-19.330612-70.008164 0l-143.673469 143.673469-39.706122 48.065306v113.893878c0 43.885714 35.526531 79.412245 79.412244 79.412245h635.29796c43.885714 0 79.412245-35.526531 79.412244-79.412245v-114.416327"
                  fill="#16C4AF"></path>
                <path
                  d="M273.763265 313.469388m-49.632653 0a49.632653 49.632653 0 1 0 99.265306 0 49.632653 49.632653 0 1 0-99.265306 0Z"
                  fill="#E5404F"></path>
                <path
                  d="M644.179592 768h-365.714286c-11.493878 0-20.897959-9.404082-20.897959-20.897959s9.404082-20.897959 20.897959-20.897959h365.714286c11.493878 0 20.897959 9.404082 20.897959 20.897959s-9.404082 20.897959-20.897959 20.897959zM461.322449 670.82449h-182.857143c-11.493878 0-20.897959-9.404082-20.897959-20.897959s9.404082-20.897959 20.897959-20.89796h182.857143c11.493878 0 20.897959 9.404082 20.897959 20.89796s-9.404082 20.897959-20.897959 20.897959z"
                  fill="#0B9682"></path>
              </svg>
            </div>
          </template>

          <div id="image-scroll-container" class="image-list">
            <n-image
              v-for="(item, index) in imageList"
              :src="item"
              @click="sendImage(item)"
              :key="index"
              width="70"
              height="70"
              lazy
              preview-disabled
              :intersection-observer-options="{root: '#image-scroll-container'}">
            </n-image>
          </div>
        </n-popover>

        <!-- 舔狗日记 -->
        <n-popconfirm :show-icon="false" positive-text="确认" @positive-click="sendPoetry(3)" negative-text="取消">
          <template #trigger>
            <div class="myEmoji">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M412.8 72.32c68.8-12.373333 144-21.013333 209.6 8.853333 14.613333 21.866667 8.426667 49.386667 12.16 73.92 15.253333 7.786667 31.36 13.653333 46.506667 21.44 3.2 1.706667 9.706667 5.12 12.906666 6.826667l-1.28 4.693333c-8.106667 24 34.026667 38.933333 19.306667 64.32-5.226667 13.653333-18.453333 8.64-29.226667 7.573334 2.453333-6.613333 7.36-19.733333 9.813334-26.24-19.733333-19.093333-53.44 20.373333-32.96 37.013333-38.826667 20.053333-83.946667 17.6-126.4 20.053333-74.133333 0.64-149.546667 3.2-222.186667-13.973333-23.04-4.16-43.306667-27.946667-36.266667-51.84 13.226667-16.32 29.44-29.866667 43.52-45.333333l5.973334-3.093334c62.4-0.426667 35.626667-87.786667 88.533333-104.213333m155.52 24.533333c11.093333 26.773333 17.066667 54.933333 20.373333 83.626667 31.893333-16.64 16.533333-83.946667-20.373333-83.626667z"
                  fill="#359F69"></path>
                <path
                  d="M568.32 96.853333c36.906667-0.32 52.266667 66.986667 20.373333 83.626667-3.306667-28.693333-9.28-56.853333-20.373333-83.626667z"
                  fill="#FFFFF8"></path>
                <path
                  d="M185.813333 133.546667c49.066667-24.106667 101.44 12.373333 138.453334 42.986666l-5.973334 3.093334c-40.64 6.613333-72.533333 42.453333-56.426666 84.373333-112.746667 75.52-155.413333 228.16-136.213334 357.653333 27.84 179.626667 193.6 322.88 375.36 324.48 185.173333 8.853333 364.053333-129.92 396.266667-313.6 31.253333-150.08-37.226667-308.053333-158.293333-399.146666a230.24 230.24 0 0 0-46.293334-45.333334l1.28-4.693333c45.44-14.08 85.546667-52.48 136.106667-42.773333 34.986667 6.186667 46.72 46.4 50.026667 76.906666 8.213333 44.906667-4.373333 89.493333-4.693334 134.293334 93.973333 163.306667 57.92 389.76-85.546666 513.28-146.666667 136.426667-393.28 139.84-544.64 9.066666C101.546667 757.12 53.76 538.88 139.093333 373.653333c23.253333-32.426667-0.853333-70.933333 3.626667-106.773333 2.026667-45.973333 0.533333-104.213333 43.093333-133.333333m651.946667 73.386666c-27.52 34.666667-27.306667 84.693333 17.706667 103.893334 3.306667-35.306667-0.426667-71.04-6.826667-105.813334-2.773333 0.533333-8.106667 1.493333-10.88 1.92m-663.146667 7.466667a781.226667 781.226667 0 0 0-0.32 82.133333c50.133333-0.853333 36.373333-72.96 0.32-82.133333z"
                  fill="#F0AD54"></path>
                <path
                  d="M681.066667 176.533333c47.786667-29.12 101.653333-65.706667 160.533333-51.093333 48.853333 16.426667 55.893333 76.266667 58.88 120.32-0.746667 35.093333 5.973333 82.026667-25.066667 106.026667 0.32-44.8 12.906667-89.386667 4.693334-134.293334-3.306667-30.506667-15.04-70.72-50.026667-76.906666-50.56-9.706667-90.666667 28.693333-136.106667 42.773333-3.2-1.706667-9.706667-5.12-12.906666-6.826667z"
                  fill="#E59308"></path>
                <path
                  d="M261.866667 264c-16.106667-41.92 15.786667-77.76 56.426666-84.373333-14.08 15.466667-30.293333 29.013333-43.52 45.333333-7.04 23.893333 13.226667 47.68 36.266667 51.84 72.64 17.173333 148.053333 14.613333 222.186667 13.973333 42.453333-2.453333 87.573333 0 126.4-20.053333 5.76-2.773333 17.386667-8.106667 23.146666-10.773333 10.773333 1.066667 24 6.08 29.226667-7.573334 14.72-25.386667-27.413333-40.32-19.306667-64.32 17.6 12.906667 33.066667 28.16 46.293334 45.333334-9.173333 22.506667-20.266667 48.746667-47.04 53.973333-109.013333 30.08-223.466667 16.96-335.04 18.24-34.346667-6.613333-68.266667-18.346667-95.04-41.6z"
                  fill="#376C55"></path>
                <path
                  d="M837.76 206.933333c2.773333-0.426667 8.106667-1.386667 10.88-1.92 6.4 34.773333 10.133333 70.506667 6.826667 105.813334-45.013333-19.2-45.226667-69.226667-17.706667-103.893334zM174.613333 214.4c36.053333 9.173333 49.813333 81.28-0.32 82.133333-1.386667-27.413333-1.173333-54.826667 0.32-82.133333z"
                  fill="#FDEC78"></path>
                <path
                  d="M659.626667 270.72c-20.48-16.64 13.226667-56.106667 32.96-37.013333-2.453333 6.506667-7.36 19.626667-9.813334 26.24-5.76 2.666667-17.386667 8-23.146666 10.773333z"
                  fill="#FFFFF8"></path>
                <path
                  d="M738.986667 233.386667c121.066667 91.093333 189.546667 249.066667 158.293333 399.146666-0.32-24.64-0.853333-49.28-3.52-73.813333-55.36 18.133333-117.44 10.24-163.946667-26.026667 32.533333-20.693333 77.44-47.786667 68.16-93.44-15.466667-52.48-75.413333-66.026667-122.24-76.16-29.866667-19.2-66.986667-41.173333-102.933333-24-47.786667 18.133333-55.466667 86.506667-21.866667 121.28 25.066667 63.786667 96.533333 77.12 157.546667 73.493334-42.88 10.986667-84.586667 50.453333-130.453333 29.12-38.613333-11.733333-62.933333-57.706667-108.16-48.426667-44.16-4.586667-68.053333 35.84-100.266667 57.066667-33.813333-2.666667-68.48-13.653333-94.08-36.8 58.133333 2.133333 121.813333-21.546667 137.28-83.626667 36.053333-23.04 24.106667-77.76-4.16-102.506667-19.52-21.76-50.773333-16.32-76.8-17.92 8.426667-8.32 16.853333-16.746667 25.066667-25.173333 111.573333-1.28 226.026667 11.84 335.04-18.24 26.773333-5.226667 37.866667-31.466667 47.04-53.973333z"
                  fill="#FDEC78"></path>
                <path
                  d="M125.653333 621.653333c-19.2-129.493333 23.466667-282.133333 136.213334-357.653333 26.773333 23.253333 60.693333 34.986667 95.04 41.6-8.213333 8.426667-16.64 16.853333-25.066667 25.173333-19.84 11.733333-38.293333 25.386667-57.493333 38.08-35.52 14.293333-70.933333 38.826667-78.293334 79.04 2.56 43.413333 42.88 66.666667 75.626667 87.146667-46.72 11.733333-94.506667-4.053333-141.653333 2.24-3.946667 27.946667-4.48 56.213333-4.373334 84.373333z"
                  fill="#FFCD73"></path>
                <path
                  d="M331.84 330.773333c26.026667 1.6 57.28-3.84 76.8 17.92 28.266667 24.746667 40.213333 79.466667 4.16 102.506667-11.306667-64.533333-79.36-91.84-138.453333-82.346667 19.2-12.693333 37.653333-26.346667 57.493333-38.08zM572.8 339.093333c35.946667-17.173333 73.066667 4.8 102.933333 24-53.546667 10.666667-116.586667 35.2-124.8 97.28-33.6-34.773333-25.92-103.146667 21.866667-121.28z"
                  fill="#FFFFF8"></path>
                <path
                  d="M550.933333 460.373333c8.213333-62.08 71.253333-86.613333 124.8-97.28 46.826667 10.133333 106.773333 23.68 122.24 76.16 9.28 45.653333-35.626667 72.746667-68.16 93.44-5.333333 0.32-16 0.853333-21.333333 1.173334-61.013333 3.626667-132.48-9.706667-157.546667-73.493334m91.626667-74.026666c-37.333333 7.04-87.786667 41.92-69.653333 85.44 31.146667 50.346667 100.8 56.746667 153.28 42.88 22.186667-15.146667-25.813333-12.586667-30.4-27.946667-32.426667-37.226667 4.8-90.56 50.133333-87.146667-29.653333-21.12-69.12-21.013333-103.36-13.226666m116.373333 31.36c-20.266667 4.48-15.573333 43.306667 7.466667 37.866666 21.973333-3.413333 14.4-43.946667-7.466667-37.866666zM196.053333 447.893333c7.36-40.213333 42.773333-64.746667 78.293334-79.04 59.093333-9.493333 127.146667 17.813333 138.453333 82.346667-15.466667 62.08-79.146667 85.76-137.28 83.626667l-3.84 0.213333c-32.746667-20.48-73.066667-43.733333-75.626667-87.146667m73.386667-61.12c-30.72 8.96-61.76 36.906667-57.706667 71.573334 15.573333 56.426667 92.48 75.093333 140.053334 48.746666-21.973333-9.6-50.346667-21.653333-50.346667-50.133333-7.253333-35.413333 29.546667-56.213333 60.053333-56.96-20.586667-27.52-62.72-18.88-92.053333-13.226667m104.746667 32.96c-23.786667 3.84-14.4 41.066667 7.04 35.093334 23.146667-2.133333 15.36-42.453333-7.04-35.093334z"
                  fill="#1F1F1F"></path>
                <path
                  d="M269.44 386.773333c29.333333-5.653333 71.466667-14.293333 92.053333 13.226667-30.506667 0.746667-67.306667 21.546667-60.053333 56.96 0 28.48 28.373333 40.533333 50.346667 50.133333-47.573333 26.346667-124.48 7.68-140.053334-48.746666-4.053333-34.666667 26.986667-62.613333 57.706667-71.573334zM642.56 386.346667c34.24-7.786667 73.706667-7.893333 103.36 13.226666-45.333333-3.413333-82.56 49.92-50.133333 87.146667 4.586667 15.36 52.586667 12.8 30.4 27.946667-52.48 13.866667-122.133333 7.466667-153.28-42.88-18.133333-43.52 32.32-78.4 69.653333-85.44z"
                  fill="#FFFFF8"></path>
                <path
                  d="M374.186667 419.733333c22.4-7.36 30.186667 32.96 7.04 35.093334-21.44 5.973333-30.826667-31.253333-7.04-35.093334zM758.933333 417.706667c21.866667-6.08 29.44 34.453333 7.466667 37.866666-23.04 5.44-27.733333-33.386667-7.466667-37.866666z"
                  fill="#7B8B70"></path>
                <path
                  d="M369.6 571.626667c32.213333-21.226667 56.106667-61.653333 100.266667-57.066667 45.226667-9.28 69.546667 36.693333 108.16 48.426667 45.866667 21.333333 87.573333-18.133333 130.453333-29.12 5.333333-0.32 16-0.853333 21.333333-1.173334 46.506667 36.266667 108.586667 44.16 163.946667 26.026667 2.666667 24.533333 3.2 49.173333 3.52 73.813333-32.213333 183.68-211.093333 322.453333-396.266667 313.6-181.76-1.6-347.52-144.853333-375.36-324.48-0.106667-28.16 0.426667-56.426667 4.373334-84.373333 47.146667-6.293333 94.933333 9.493333 141.653333-2.24l3.84-0.213333c25.6 23.146667 60.266667 34.133333 94.08 36.8m-36.373333 37.12c-22.826667 8.106667-12.8 39.36-5.333334 55.786666 11.093333 27.306667 49.493333 30.826667 56.106667 60.586667 3.733333 20.373333 25.28 40 19.093333 60.16-33.066667 7.573333-74.666667 4.053333-95.573333-26.24-9.92-8.32-15.253333-28.053333-31.466667-24-5.12 40.746667 40.426667 68.266667 75.413334 75.946667 34.986667 10.88 68.266667-18.56 102.826666-6.293334 61.973333 20.373333 132.16-0.106667 178.24-44.8 9.706667-9.173333 12.053333-20.16 6.826667-33.066666-26.88 14.186667-45.226667 41.173333-74.666667 51.306666-58.88 25.493333-149.653333 10.133333-161.386666-64 11.946667-33.6 70.933333-58.24 50.56-101.546666-37.546667-15.146667-81.6-11.413333-120.64-3.84z"
                  fill="#FFFFAB"></path>
                <path
                  d="M333.226667 608.746667c39.04-7.573333 83.093333-11.306667 120.64 3.84 20.373333 43.306667-38.613333 67.946667-50.56 101.546666 11.733333 74.133333 102.506667 89.493333 161.386666 64 29.44-10.133333 47.786667-37.12 74.666667-51.306666 5.226667 12.906667 2.88 23.893333-6.826667 33.066666-46.08 44.693333-116.266667 65.173333-178.24 44.8-34.56-12.266667-67.84 17.173333-102.826666 6.293334-34.986667-7.68-80.533333-35.2-75.413334-75.946667 16.213333-4.053333 21.546667 15.68 31.466667 24 20.906667 30.293333 62.506667 33.813333 95.573333 26.24 6.186667-20.16-15.36-39.786667-19.093333-60.16-6.613333-29.76-45.013333-33.28-56.106667-60.586667-7.466667-16.426667-17.493333-47.68 5.333334-55.786666m95.573333 21.973333c-12.586667 43.2 42.453333-11.84 0 0z"
                  fill="#7D180F"></path>
                <path d="M428.8 630.72c42.453333-11.84-12.586667 43.2 0 0z" fill="#FFFFF8"></path>
              </svg>
            </div>
          </template>
          💕&nbsp;投递舔狗日记？
        </n-popconfirm>

        <!-- 鸡汤来咯 -->
        <n-popconfirm :show-icon="false" positive-text="确认" @positive-click="sendPoetry(4)" negative-text="取消">
          <template #trigger>
            <div class="myEmoji">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M841.5 641.1c0 175.8-147.2 248.4-328.7 248.4C331.2 889.5 184 817 184 641.1c0-175.8 147.2-388.3 328.7-388.3 181.6 0 328.8 212.5 328.8 388.3z"
                  fill="#FFFFFF"></path>
                <path
                  d="M512.8 900.4c-212.7 0-339.7-96.9-339.7-259.3 0-180.8 151.5-399.3 339.7-399.3s339.6 218.4 339.6 399.3c0 162.4-126.9 259.3-339.6 259.3z m0-636.7C336.7 263.7 195 470.2 195 641.1c0 214.3 222.3 237.5 317.8 237.5s317.8-23.1 317.8-237.5c0-170.9-141.8-377.4-317.8-377.4z"
                  fill="#7F0518"></path>
                <path
                  d="M660.2 160.8c-44.7-71.8-88.2-21.1-97.5-8.7 6.4-15.7 8.3-76.8-51.9-76.8-56.8 0-49.4 56.9-45.7 74.4-12.3-15.3-53.4-56.6-95.5 11.2-50.4 81 96.3 204.7 96.3 204.7 7.5 5.3 14.6 8.6 21.4 10.4 8.3 2.9 19.5 5.6 32.4 5.6 18 0 31.6-7.4 38.8-12.5 1.8-1.1 3.6-2.2 5.5-3.6-0.1 0 146.6-123.8 96.2-204.7z"
                  fill="#FF6A48"></path>
                <path
                  d="M519.6 392.5c-12 0-24.1-2.1-36-6.2-7.6-2-16-6-24.1-11.8-7-5.9-154-131.4-99.2-219.4 16.9-27.1 36-41.5 56.9-42.7 13-1.1 25.1 3.8 34.7 10.2 0.5-13.2 3.7-27.8 12.9-39.1 10.3-12.6 25.8-19 46.1-19 30.4 0 46.2 14 54.2 25.8 6.9 10.2 10.2 22.2 11.3 33.5 9.7-6.8 21.8-12.3 35.5-11.4 21.2 0.9 40.5 15.3 57.7 42.8 54.8 88-92.2 213.5-98.5 218.8-2.9 2.1-5 3.4-7.1 4.7-7 4.7-23.1 13.8-44.4 13.8zM420 134c-0.5 0-1.1 0-1.6 0.1-13.4 0.8-26.7 11.7-39.6 32.5-44.8 72 92.7 189.4 94 190.6 5.3 3.7 11.3 6.7 17.2 8.2 7.4 2.5 17.6 5.3 29.6 5.3 15.3 0 27-6.6 32.6-10.5 2.3-1.4 3.9-2.4 5.5-3.5 0.7-0.6 138.2-118 93.3-190-13.1-21-26.5-31.9-40-32.5-18.5-1-34.9 18.6-39.4 24.6-3.3 4.4-9.3 5.6-14.1 3-4.8-2.7-6.8-8.5-4.8-13.6 2.7-6.6 4.9-29.9-5.7-45.7-7.2-10.7-19.3-16.1-36-16.1-13.6 0-23.1 3.6-29.1 11-12.1 14.9-7.6 42.2-5.9 50.2 1 5-1.5 10-6 12.1-4.6 2.1-10 0.9-13.2-3-5.8-7.1-20.1-22.7-36.8-22.7z"
                  fill="#7F0518"></path>
                <path
                  d="M530.8 809.8l-0.4-13.2s-6.2 2-15.6 5.9c-9.4-3.9-15.6-5.9-15.6-5.9l-0.4 13.2c-37.2 18.7-91.1 57.2-65.1 116 15.1 34 53.9 28.3 83.3-3.9 4.9 5.3 55.4 56.5 78.7 3.9 26.2-58.8-27.7-97.3-64.9-116z"
                  fill="#FF6A48"></path>
                <path
                  d="M566.1 960.3c-19.6 0-38.4-13.8-48.9-23.1-18.5 16.8-39.7 24.9-58.2 21.9-15.6-2.5-28.1-12.8-35.2-28.9-21.5-48.7 1.3-93.7 64.3-127.2l0.2-6.8c0.1-3.4 1.8-6.6 4.6-8.6s6.3-2.6 9.6-1.5c0 0 4.7 1.5 12.2 4.5 7.5-3 12.3-4.5 12.3-4.5 3.2-1 6.8-0.4 9.6 1.5 2.8 2 4.5 5.2 4.6 8.6l0.2 6.8c63 33.5 85.8 78.5 64.3 127.2-9.5 21.5-23.3 27.9-33.3 29.6-2 0.3-4.1 0.5-6.3 0.5z m-49-49.3c3.1 0 6 1.3 8.1 3.6 6.4 7 28.5 26.4 43.8 23.7 2.5-0.4 10.1-1.7 16.8-16.8 21.5-48.5-20.8-82.2-60-101.8-3-1.5-5.1-4.3-5.8-7.5-0.3 0.1-0.7 0.3-1 0.4-2.7 1.1-5.7 1.1-8.5 0-0.3-0.1-0.7-0.3-1-0.4-0.7 3.2-2.8 6-5.8 7.5-39.2 19.6-81.4 53.3-60 101.8 4.2 9.4 10.4 14.8 18.7 16.2 13.7 2.3 31.5-6.6 46.5-23 2.2-2.4 5.2-3.7 8.2-3.7z"
                  fill="#7F0518"></path>
                <path
                  d="M662.8 722.5s-38.4-108.4-142.4-108.4-146.1 101.8-146.1 101.8c0.7 2 30.8 131.7 139.9 131.7 95.2-0.1 133.1-83.1 148.6-125.1z"
                  fill="#FFC831"></path>
                <path
                  d="M514.2 858.4c-108 0-144.1-115.8-150.2-139.1-0.6-2.3-0.7-5.5 0.3-7.7 1.8-4.4 46.1-108.6 156.2-108.6 110.5 0 152.3 114.6 152.7 115.7 0.9 2.4 0.8 5-0.1 7.4-32.4 87.9-85.8 132.3-158.9 132.3zM385.8 716.8c8.4 28.8 41.5 119.8 128.3 119.8 62 0 108-38.4 136.9-114.1-7.5-18.3-45-97.6-130.7-97.6-85.4 0-126.4 75.2-134.5 91.9z"
                  fill="#7F0518"></path>
                <path d="M697 579.1m-54.5 0a54.5 54.5 0 1 0 109 0 54.5 54.5 0 1 0-109 0Z" fill="#7F0518"></path>
                <path d="M683.3 568m-25.1 0a25.1 25.1 0 1 0 50.2 0 25.1 25.1 0 1 0-50.2 0Z" fill="#FFFFFF"></path>
                <path d="M715.4 604.6m-11.5 0a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0-23 0Z" fill="#FFFFFF"></path>
                <path d="M329 579.1m-54.5 0a54.5 54.5 0 1 0 109 0 54.5 54.5 0 1 0-109 0Z" fill="#7F0518"></path>
                <path d="M315.3 568m-25.1 0a25.1 25.1 0 1 0 50.2 0 25.1 25.1 0 1 0-50.2 0Z" fill="#FFFFFF"></path>
                <path d="M347.5 604.6m-11.5 0a11.5 11.5 0 1 0 23 0 11.5 11.5 0 1 0-23 0Z" fill="#FFFFFF"></path>
                <path
                  d="M726.9 464.4c-2.6 0-5.3-1-7.4-2.9-10.2-9.3-20.2-14-29.7-13.1-13.7 0.9-23.1 11.9-23.2 12.1-3.9 4.6-10.8 5.3-15.3 1.4-4.6-3.8-5.3-10.6-1.6-15.3 0.6-0.7 15.1-18.3 38.4-20 15.9-1 31.4 5.2 46.3 18.8 4.4 4.1 4.7 11 0.7 15.4-2.3 2.5-5.3 3.6-8.2 3.6z"
                  fill="#7F0518"></path>
                <path
                  d="M301.6 464.4c-2.4 0-4.9-0.8-6.9-2.4-4.7-3.8-5.4-10.7-1.6-15.4 0.6-0.7 15.2-18.3 38.4-20 15.8-1 31.4 5.2 46.2 18.8 4.4 4.1 4.7 11 0.6 15.4-4 4.5-11 4.7-15.4 0.6-10.2-9.4-20.3-14-29.9-13.1-13.7 1-22.9 11.9-23 12-2.1 2.8-5.2 4.1-8.4 4.1z"
                  fill="#7F0518"></path>
                <path
                  d="M518.9 771c-70.7 0-123.5-34.6-124.3-35.1-5-3.4-6.3-10.1-3-15.1 3.4-5 10.1-6.4 15.1-3 4.4 2.9 108.1 70.4 216.2-0.1 5-3.3 11.8-1.9 15.1 3.2 3.3 5.1 1.8 11.8-3.2 15.1-40.2 26.3-80.4 35-115.9 35z"
                  fill="#7F0518"></path>
              </svg>
            </div>
          </template>
          💕&nbsp;鸡汤来咯！
        </n-popconfirm>

        <!-- 社会语录 -->
        <n-popconfirm :show-icon="false" positive-text="确认" @positive-click="sendPoetry(5)" negative-text="取消">
          <template #trigger>
            <div class="myEmoji">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M925.013333 894.293333h-819.2V853.333333c0-95.573333 61.44-177.493333 150.186667-208.213333l98.986667-34.133333 6.826666 6.826666c44.373333 47.786667 98.986667 71.68 153.6 71.68s112.64-27.306667 153.6-71.68l6.826667-6.826666 98.986667 34.133333c88.746667 30.72 150.186667 116.053333 150.186666 208.213333v40.96z m-785.066666-34.133333h750.933333V853.333333c0-78.506667-51.2-150.186667-126.293333-177.493333l-75.093334-27.306667c-47.786667 47.786667-109.226667 75.093333-170.666666 75.093334s-122.88-27.306667-170.666667-75.093334l-78.506667 27.306667c-75.093333 27.306667-126.293333 98.986667-126.293333 177.493333v6.826667zM430.08 382.293333h34.133333v34.133334h-34.133333zM566.613333 382.293333h34.133334v34.133334h-34.133334z"
                  fill="#2D3545"></path>
                <path
                  d="M515.413333 655.36c-85.333333 0-160.426667-68.266667-191.146666-170.666667-27.306667 0-47.786667-37.546667-47.786667-85.333333 0-37.546667 13.653333-68.266667 34.133333-81.92v-3.413333c0-95.573333 64.853333-177.493333 157.013334-197.973334l6.826666 34.133334c-78.506667 17.066667-129.706667 85.333333-129.706666 167.253333v10.24l3.413333 23.893333-20.48-3.413333c-3.413333 3.413333-17.066667 20.48-17.066667 51.2 0 34.133333 13.653333 51.2 17.066667 51.2l20.48-13.653333 6.826667 20.48c23.893333 95.573333 88.746667 160.426667 160.426666 160.426666s136.533333-64.853333 160.426667-160.426666l6.826667-23.893334 20.48 13.653334c6.826667 0 17.066667-17.066667 17.066666-51.2 0-30.72-10.24-47.786667-17.066666-51.2l-20.48 6.826666 3.413333-23.893333v-10.24c0-78.506667-54.613333-146.773333-129.706667-167.253333l6.826667-34.133334c92.16 20.48 157.013333 102.4 157.013333 197.973334v6.826666c20.48 10.24 34.133333 40.96 34.133334 78.506667 0 47.786667-20.48 81.92-47.786667 85.333333-30.72 102.4-105.813333 170.666667-191.146667 170.666667z"
                  fill="#2D3545"></path>
                <path
                  d="M546.133333 279.893333h-61.44l-40.96-204.8h143.36l-40.96 204.8z m-34.133333-34.133333h6.826667l27.306666-136.533333h-61.44l27.306667 136.533333z"
                  fill="#2D3545"></path>
              </svg>
            </div>
          </template>
          💕&nbsp;传教社会语录？
        </n-popconfirm>

        <!-- 诗词炮弹 -->
        <n-popconfirm :show-icon="false" positive-text="确认" @positive-click="sendPoetry(1)" negative-text="取消">
          <template #trigger>
            <div class="myEmoji">
              <svg viewBox="0 0 1024 1024" width="25" height="25">
                <path
                  d="M900.096 444.928c-14.848-204.8-210.432-210.432-210.432-210.432s-72.704-143.872-227.84-118.272C294.4 143.36 278.016 313.856 278.016 313.856S133.12 350.208 138.24 501.76c5.12 160.768 162.304 184.32 162.304 184.32h420.352s194.048-39.936 179.2-241.152z"
                  fill="#D1D1D1"></path>
                <path
                  d="M399.872 754.688l-38.4 128.512H337.92l-25.6-90.112c-1.024-4.096-1.536-8.192-2.048-12.288h-0.512c0 3.072-1.024 7.168-2.56 12.288l-27.648 90.624h-23.04l-38.4-128.512h23.552l25.6 95.232c1.024 3.072 1.536 6.656 1.536 11.776h1.024c0.512-4.096 1.024-8.192 2.048-12.288l27.648-94.72h20.992l27.136 95.232c1.024 2.56 1.536 6.656 2.048 11.776h1.024c0-3.584 1.024-7.168 2.048-11.776l25.088-95.232h22.016zM413.696 820.224c0-20.992 6.144-37.888 17.92-50.176 11.776-12.288 28.16-18.432 48.128-18.432 19.456 0 34.304 6.144 45.568 17.92s16.384 28.16 16.384 49.152c0 20.48-5.632 36.864-17.408 49.152-11.776 12.288-27.136 18.944-47.104 18.944-19.456 0-34.304-6.144-46.08-17.92-11.776-13.312-17.408-29.184-17.408-48.64z m23.552-1.024c0 14.848 3.584 26.624 11.264 34.816 7.68 8.704 17.408 12.8 30.208 12.8 12.8 0 23.04-4.096 29.696-12.288 6.656-8.192 10.24-20.48 10.24-35.84 0-15.872-3.584-27.648-10.24-35.84-6.656-8.192-16.896-12.8-29.696-12.8s-23.04 4.608-30.208 13.312c-7.68 8.704-11.264 20.992-11.264 35.84zM642.56 777.216c-3.584-2.56-9.216-4.096-15.872-4.096-8.704 0-15.872 4.096-22.016 12.288-5.632 8.192-8.704 18.944-8.704 32.768v65.024H573.44v-128.512h22.528v26.112h0.512c6.656-18.944 17.92-28.16 33.792-28.16 5.12 0 9.216 0.512 11.776 1.536v23.04zM772.608 883.2h-22.528v-21.504h-0.512c-9.216 16.384-24.064 24.576-43.52 24.576-16.384 0-29.184-5.632-38.912-17.408-9.728-11.776-14.336-27.136-14.336-47.104 0-20.992 5.632-38.4 16.384-51.2s25.088-19.456 42.496-19.456 30.208 6.656 37.888 20.48h0.512v-79.36h22.528v190.976z m-22.528-76.8c0-10.24-3.584-18.944-10.24-25.6-6.656-7.168-15.36-10.24-25.088-10.24-12.288 0-21.504 4.608-28.672 13.312-7.168 9.216-10.24 21.504-10.24 36.864 0 14.336 3.584 25.6 10.24 33.792 6.656 8.192 15.872 12.288 26.624 12.288s19.968-4.096 27.136-12.288 10.24-18.432 10.24-30.72v-17.408z"
                  fill="#9C9C9C"></path>
              </svg>
            </div>
          </template>
          💕&nbsp;发射诗词炮弹？
        </n-popconfirm>
      </div>

      <!-- 输入框 -->
      <textarea @keydown="send($event)"
                v-model="msg"
                maxlength="1000"
                spellcheck="false"
                class="message-content">
            </textarea>
      <!-- 发送 -->
      <div class="message-send">
        <span style="color: var(--greyFont);margin-right: 15px;font-size: 12px">Ctrl+Enter：换行 | Enter：发送</span>
        <n-button @click="doSend()" type="info">
          发送
        </n-button>
      </div>
    </div>

    <!-- 聊天图片弹出框 -->
    <n-modal v-model:show="showPictureDialog">
      <div style="padding: 40px;background: var(--white);border-radius: 5px;width: 20%">
        <div style="margin: 0 0 25px;text-align: center;font-size: 18px">上传图片</div>
        <uploadPicture :prefix="picturePrefix" @addPicture="addPicture" :maxSize="5"
                       :maxNumber="1"></uploadPicture>
      </div>
    </n-modal>
  </div>
</template>

<script>
  import {useStore} from 'vuex';

  import {useDialog} from 'naive-ui';

  import {nextTick} from 'vue';

  import {ElMessage} from "element-plus";

  import emoji from "./emoji";
  import uploadPicture from "./uploadPicture";
  import {reactive, getCurrentInstance, onMounted, onBeforeUnmount, watchEffect, toRefs} from 'vue';

  export default {
    components: {
      uploadPicture,
      emoji
    },
    props: {
      currentChatFriendId: {
        type: Number
      },
      currentChatGroupId: {
        type: Number
      },
      friends: {
        type: Object
      },
      groups: {
        type: Object
      },
      imMessages: {
        type: Object
      },
      groupMessages: {
        type: Object
      },
      imageList: {
        type: Array
      }
    },
    setup(props, context) {
      const globalProperties = getCurrentInstance().appContext.config.globalProperties;
      const $common = globalProperties.$common;
      const $http = globalProperties.$http;
      const $constant = globalProperties.$constant;
      const store = useStore();
      const dialog = useDialog();

      let data = reactive({
        //发送消息
        msg: '',

        //聊天图片
        showPictureDialog: false,
        picturePrefix: '',
        showPopoverImage: false
      })

      function sendPicture() {
        if (!$common.isEmpty(props.currentChatFriendId)) {
          data.picturePrefix = 'im/friendMessage';
          data.showPictureDialog = true;
        } else if (!$common.isEmpty(props.currentChatGroupId)) {
          data.picturePrefix = 'im/groupMessage';
          data.showPictureDialog = true;
        }
      }

      function addPicture(res) {
        data.msg += "<" + store.state.currentUser.username + "," + res + ">";
        data.showPictureDialog = false;
      }

      function openFriendCircle(userId, avatar, username) {
        context.emit("openFriendCircle", userId, avatar, username);
      }

      function addEmoji(key) {
        data.msg += key;
      }

      function sendImage(url) {
        data.msg += "<" + store.state.currentUser.username + "," + url + ">";
        data.showPopoverImage = false;
        doSend();
      }

      function sendPoetry(type) {
        if (type === 1) {
          sendGuShi();
        } else if (type === 2) {
          sendYiyan();
        } else if (type === 3) {
          sendDog();
        } else if (type === 4) {
          sendJitang();
        } else if (type === 5) {
          sendShehui();
        }
      }

      function sendGuShi() {
        let xhr = new XMLHttpRequest();
        xhr.open('get', $constant.jinrishici);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            let guShi = JSON.parse(xhr.responseText).content;
            if (!$common.isEmpty(guShi)) {
              data.msg = guShi;
              doSend()
            }
          }
        };
        xhr.send();
      }

      function sendYiyan() {
        let xhr = new XMLHttpRequest();
        xhr.open('get', $constant.yiyan);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            let yiyan = xhr.responseText;
            if (!$common.isEmpty(yiyan)) {
              data.msg = yiyan.substring(1, yiyan.length - 1);
              doSend()
            }
          }
        };
        xhr.send();
      }

      function sendDog() {
        let xhr = new XMLHttpRequest();
        xhr.open('get', $constant.dog);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            let dog = xhr.responseText;
            if (!$common.isEmpty(dog)) {
              data.msg = dog.substring(1, dog.length - 1);
              doSend()
            }
          }
        };
        xhr.send();
      }

      function sendJitang() {
        let xhr = new XMLHttpRequest();
        xhr.open('get', $constant.jitang);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            let jitang = xhr.responseText;
            if (!$common.isEmpty(jitang)) {
              data.msg = jitang.substring(1, jitang.length - 1);
              doSend()
            }
          }
        };
        xhr.send();
      }

      function sendShehui() {
        let xhr = new XMLHttpRequest();
        xhr.open('get', $constant.shehui);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            let shehui = xhr.responseText;
            if (!$common.isEmpty(shehui)) {
              data.msg = shehui.substring(1, shehui.length - 1);
              doSend()
            }
          }
        };
        xhr.send();
      }

      function send(e) {
        if (e && (e.ctrlKey || e.shiftKey) && e.keyCode === 13) {
          e.returnValue = false;
          data.msg = data.msg + '\n';
        } else if (e && e.keyCode === 13) {
          e.returnValue = false;
          doSend();
        }
      }

      function doSend() {
        if ($common.isEmpty(data.msg)) {
          return;
        }

        if (!$common.isEmpty(props.currentChatFriendId)) {
          let message = {
            messageType: 1,
            content: data.msg,
            fromId: store.state.currentUser.id,
            toId: props.currentChatFriendId,
            avatar: store.state.currentUser.avatar
          }
          let success = false;
          context.emit("sendMsg", JSON.stringify(message), val => {
            success = val;
          });
          if (success) {
            data.msg = '';
          }
        } else if (!$common.isEmpty(props.currentChatGroupId)) {
          let message = {
            messageType: 2,
            content: data.msg,
            fromId: store.state.currentUser.id,
            groupId: props.currentChatGroupId,
            avatar: store.state.currentUser.avatar,
            username: store.state.currentUser.username
          }
          let success = false;
          context.emit("sendMsg", JSON.stringify(message), val => {
            success = val;
          });
          if (success) {
            data.msg = '';
          }
        }
      }

      return {
        ...toRefs(data),
        openFriendCircle,
        addEmoji,
        addPicture,
        sendPicture,
        sendImage,
        sendPoetry,
        send,
        doSend
      }
    }
  }
</script>

<style scoped>
  .msg-container {
    background: var(--midWhite);
    overflow-y: scroll;
    height: calc(100% - 240px);
  }

  .msg-one {
    margin: 15px 20px;
    display: flex;
    align-items: flex-start;
  }

  .message {
    max-width: 50%;
    padding: 5px 10px;
    line-height: 25px;
    word-break: break-all;
    position: relative;
    color: var(--black);
    margin: 0 12px 0 12px;
  }

  .message::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 3px solid;
    top: 0;
  }

  .message-left .message {
    background-color: var(--white);
    border-radius: 0 4px 4px 4px;
  }

  .message-left .message::before {
    left: -6px;
    border-color: var(--white) var(--white) transparent transparent;
  }

  .message-right {
    flex-direction: row-reverse;
  }

  .message-right .message {
    background-color: var(--messageColor);
    border-radius: 4px 0 4px 4px;
  }

  .message-right .message::before {
    right: -6px;
    border-color: var(--messageColor) transparent transparent var(--messageColor);
  }

  .msg-date, .msg-user {
    font-size: 12px;
    color: var(--greyFont);
  }

  .myEmoji {
    transition: all 0.5s;
    margin-right: 15px;
    cursor: pointer;
  }

  .myEmoji:hover {
    transform: scale(1.2);
  }

  .message-content {
    width: calc(100% - 30px);
    margin: 0 15px;
    box-sizing: border-box;
    height: calc(100% - 100px);
    display: block;
    border: none;
    outline: none;
    box-shadow: none;
    resize: none;
    font-size: 18px;
  }

  .message-send {
    float: right;
    padding: 10px 30px 15px;
  }

  .message-send .n-button {
    height: 30px;
    padding: 10px 25px;
  }

  .image-list {
    overflow: auto;
    max-width: 400px;
    max-height: 200px;
    display: flex;
    flex-flow: wrap;
    gap: 10px;
  }


  @media screen and (max-width: 400px) {
    .msg-one {
      margin: 15px 10px;
    }

    .image-list {
      max-width: 230px !important;
    }
  }
</style>
